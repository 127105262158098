import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { t } from '../../../../../types/translation/Translator';
import CreateOrderProductPane from './Panes/CreateOrderProductPane';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AddOrderProductResponse,
  AddOrderProductVariables,
  OrderMutations,
} from '../../../../../graphql/order.graphql';
import { Order } from '../../../../../types/order';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import {
  GetProductTransactionsResponse,
  GetProductTransactionsVariables,
  ProductTransactionQueries,
} from '../../../../../graphql/productTransaction.graphql';
import { ProductTransaction } from '../../../../../types/productTransaction';
import { ProductTransactionContext } from '../../../../../context/ProductTransactionContext';

interface AddOrderProductsModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  order: Order;
}

export default function AddOrderProductsModal({ open, setOpen, order }: AddOrderProductsModalInputProps) {
  if (!open) return null;

  const { currentCompany } = useContext(CompanyContext);
  const { productTransactions, setProductTransactions } = useContext(ProductTransactionContext);

  const [error, setError] = useState<string>('');
  const [orderInput, setOrderInput] = useState<Order>(
    new Order({ companyId: currentCompany.id, id: order.id, stockLocationId: order.stockLocationId, type: order.type }),
  );

  const [create, { loading }] = useMutation<AddOrderProductResponse, AddOrderProductVariables>(
    OrderMutations.addOrderProduct,
    {
      onCompleted: res => {
        fetchProductTransactions();
      },
      onError: err => setError(err.message),
    },
  );

  const [fetchProductTransactions, { loading: productTransactionsLoading }] = useLazyQuery<
    GetProductTransactionsResponse,
    GetProductTransactionsVariables
  >(ProductTransactionQueries.get, {
    variables: {
      companyId: currentCompany.companyId,
      batchSize: orderInput.products.size < 100 ? 100 : orderInput.products.size,
    },
    onCompleted: async res => {
      res.productTransactions.data.forEach(pt => productTransactions.set(pt.id, new ProductTransaction(pt)));
      setProductTransactions(new Map(productTransactions));
      handleClose();
    },
  });

  const handleClose = () => {
    setOrderInput(
      new Order({
        companyId: currentCompany.id,
        id: order.id,
        stockLocationId: order.stockLocationId,
        type: order.type,
      }),
    );
    setOpen(false);
  };

  const handleFinish = async () => {
    try {
      await create({
        variables: {
          companyId: currentCompany.companyId,
          orderId: order.id,
          specifiers: [...orderInput.products.values()].map(p => {
            delete p.id;
            (p.customFields as any) = p.customFieldValues();
            return p;
          }),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      height='550px'
      title={t().addProductsToOrder.singular.label}
      testId={testIds.createOrderModal}
    >
      <CreateOrderProductPane
        setError={setError}
        order={orderInput}
        loading={loading || productTransactionsLoading}
        setOrder={setOrderInput}
        add={handleFinish}
        cancel={handleClose}
        modalHeight={550}
      />
    </Modal>
  );
}
