import React, { useContext, useEffect } from 'react';
import {
  CustomFieldEntitySubType,
  CustomFieldEntityType,
  CustomFieldType,
  WithCustomFields,
} from '../../../types/customField';
import { Order, OrderType } from '../../../types/order';
import { CustomFieldContext } from '../../../context/CustomFieldContext';
import { Grid } from '@mui/material';
import CustomFieldInput from './CustomFieldInput';
import { classes, testIds } from '../../../util/identifiers/identifiers.util';

interface CustomFieldOrderInputProps {
  order: Order;
  setOrder: (order: Order) => void;
  disabled?: boolean;
}

export default function CustomFieldOrderInput({ order, setOrder, disabled = false }: CustomFieldOrderInputProps) {
  const { customFields } = useContext(CustomFieldContext);

  const entitySubtype =
    order.type === OrderType.inbound ? CustomFieldEntitySubType.inbound : CustomFieldEntitySubType.outbound;

  const items = [...customFields.values()]
    .filter(
      v => v.entityType === CustomFieldEntityType.order && (!v.entitySubtype || v.entitySubtype === entitySubtype),
    )
    .sort((a, b) => b.index - a.index);

  useEffect(() => {
    if (!order.customFields.size) {
      [...customFields.values()]
        .filter(cf => cf.defaultValue)
        .map(cf =>
          setOrder(
            order.withCustomField({
              id: cf.id,
              mandatory: cf.mandatory,
              name: cf.name,
              value: String(cf.defaultValue),
              type: cf.type,
            }),
          ),
        );
    }
  }, [order]);

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      {items.map(item => {
        return (
          <Grid
            key={item.id}
            item
            xs={6}
            mt={item.type === CustomFieldType.bool ? 2 : 0}
            className={classes.customFieldInput.name}
            data-testid={testIds.customFieldInput(item.name)}
          >
            <CustomFieldInput
              disabled={disabled}
              value={WithCustomFields.toCustomFieldsMap(order).get(item.id)?.value}
              entity={order}
              item={item}
              onChange={setOrder}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
