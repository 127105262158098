import React, { useContext, useMemo, useState } from 'react';
import { ProductContext } from '../../../../../../context/ProductContext';
import { Divider, Grid } from '@mui/material';
import {
  ProductTransaction,
  ProductTransactionStatus,
  ProductTransactionType,
} from '../../../../../../types/productTransaction';
import { t } from '../../../../../../types/translation/Translator';
import { ProductMasterDataContext } from '../../../../../../context/ProductMasterDataContext';
import { StockLocationContext } from '../../../../../../context/StockLocationContext';
import { BinContext } from '../../../../../../context/BinContext';
import { LotContext } from '../../../../../../context/LotContext';
import { BinStatusContext } from '../../../../../../context/BinStatusContext';
import { StockLocationRoleAssignmentContext } from '../../../../../../context/StockLocationRoleAssignmentContext';
import { useMutation } from '@apollo/client';
import {
  CreateAndProcessProductTransactionResponse,
  CreateAndProcessProductTransactionVariables,
  ProductTransactionMutations,
} from '../../../../../../graphql/productTransaction.graphql';
import { errorCodeToText } from '../../../../../../util/error.util';
import { ProductTransactionContext } from '../../../../../../context/ProductTransactionContext';
import TextInput from '../../../../Common/TextInput';
import { Product } from '../../../../../../types/product';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import Checkbox from '../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { StockLocationRole } from '../../../../../../types/stockLocationRoleAssignment';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { toQuantityString } from '../../../../../../types/unitOfMeasure';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { CancelButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { ProcessButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/ProcessButton';
import { ContainerContext } from '../../../../../../context/ContainerContext';
import { isValidDestinationContainer } from '../../../../../../util/containers.util';
import { SystemBin } from '../../../../../../types/bin';

interface QuickActionModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  pmdId: string;
  productId?: string;
}

export default function QuickActionModal({ open, setOpen, pmdId, productId }: QuickActionModalProps) {
  if (!open) return null;

  const { currentCompany } = useContext(CompanyContext);
  const { productTransactions, setProductTransactions } = useContext(ProductTransactionContext);
  const { products } = useContext(ProductContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { containers } = useContext(ContainerContext);
  const { bins } = useContext(BinContext);
  const { lots } = useContext(LotContext);
  const { binStatuses } = useContext(BinStatusContext);

  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const pmd = productMasterData.get(pmdId);
  if (!pmd) return null;

  const showContainer = useMemo(() => currentCompany.settings.featureToggles.containers.containers, [currentCompany]);

  const emptyProduct = {
    companyId: currentCompany.id,
    productMasterDataId: pmd.id,
    stockLocationId: [...stockLocations.values()][0].id,
    quantity: toQuantityString(products.get(pmdId)?.get(productId || '')?.unitQuantity || '0', pmd.unitOfMeasure),
  };

  const [product, setProduct] = useState<Product>(
    new Product(products.get(pmdId)?.get(productId || '') || emptyProduct),
  );

  const [transaction, setTransaction] = useState<ProductTransaction>(
    new ProductTransaction({
      status: ProductTransactionStatus.created,
      companyId: currentCompany.id,
      product: {
        pmdId: pmdId,
        quantity:
          pmd.serialManaged || (pmd.lpnManaged && !pmd.lotManaged)
            ? 1
            : parseFloat(toQuantityString(product.unitQuantity, pmd.unitOfMeasure)),
      },
    }),
  );

  const [isMove, setIsMove] = useState<boolean>(false);
  const [error, setError] = useState('');

  const [process, { loading }] = useMutation<
    CreateAndProcessProductTransactionResponse,
    CreateAndProcessProductTransactionVariables
  >(ProductTransactionMutations.createAndProcess, {
    onCompleted: res => {
      const transaction = res.createAndProcessTransaction[0];
      productTransactions.set(transaction.id, new ProductTransaction(transaction));
      setProductTransactions(new Map(productTransactions));
      setOpen(false);
    },
    onError: err => setError(errorCodeToText(err.message)),
  });

  const handleProcess = async () => {
    if (transaction.product.quantity === null) return;
    if (!valid()) return;

    const originalQuantity = parseFloat(toQuantityString(product.unitQuantity, pmd.unitOfMeasure));
    if (isMove) {
      transaction.type = ProductTransactionType.move;

      transaction.product.fromStockLocationId = product.stockLocationId;
      transaction.product.fromBinId = product.binId;
      transaction.product.fromContainerId = product.containerId;

      transaction.product.quantity = originalQuantity - transaction.product.quantity;
    } else if (!product?.id) {
      transaction.type = ProductTransactionType.inbound;
    } else if (transaction.product.quantity > originalQuantity) {
      transaction.type = ProductTransactionType.inbound;

      transaction.product.toBinId = product.binId;
      transaction.product.toStockLocationId = product.stockLocationId;
      if (product.containerId) transaction.product.toContainerId = product.containerId;

      transaction.product.quantity = transaction.product.quantity - originalQuantity;
    } else if (transaction.product.quantity < originalQuantity) {
      transaction.type = ProductTransactionType.outbound;

      transaction.product.fromBinId = product.binId;
      transaction.product.fromStockLocationId = product.stockLocationId;
      if (product.containerId) transaction.product.fromContainerId = product.containerId;

      transaction.product.quantity = originalQuantity - transaction.product.quantity;
    }

    transaction.product.serialNbr ||= product?.serial;
    transaction.product.lpn ||= product?.lpn;
    transaction.product.lotId ||= product?.lotId;

    transaction.product.processedQuantity = transaction.product.quantity;

    await process({
      variables: {
        productTransactions: [transaction.forCreate()],
      },
    });
  };

  const binProductQuantityMap = new Map<string, string>();
  [...(products.get(pmd.id)?.values() || [])].forEach(p => binProductQuantityMap.set(p.binId, p.unitQuantity));

  const containerProductQuantityMap = new Map<string, string>();
  [...(products.get(pmd.id)?.values() || [])].forEach(p =>
    p.containerId ? containerProductQuantityMap.set(p.containerId, p.unitQuantity) : null,
  );

  const inboundAllowedBins = [...bins.values()].filter(
    bin => binStatuses.get(bins.get(bin.id)?.binStatusId || '')?.inboundAllowed,
  );

  const valid = () => {
    if (!productId && (!transaction.product.quantity || transaction.product.quantity <= 0)) {
      return false;
    }

    if (
      transaction.product.quantity.toString() === toQuantityString(product.unitQuantity, pmd.unitOfMeasure) &&
      product.id
    ) {
      return false;
    }

    if (
      (pmd.serialManaged || (pmd.lpnManaged && !pmd.lotManaged)) &&
      transaction.product.quantity !== 0 &&
      transaction.product.quantity !== 1
    )
      return false;

    if (isMove) {
      if (!transaction.product.toStockLocationId) return false;
      if (!transaction.product.toBinId) return false;

      if (
        parseFloat(transaction.product.quantity.toString()) >
        parseFloat(toQuantityString(product.unitQuantity, pmd.unitOfMeasure))
      ) {
        return false;
      }
    }

    if (!product?.id) {
      if (!transaction.product.toStockLocationId) return false;
      if (!transaction.product.toBinId) return false;

      if (pmd.serialManaged && !transaction.product.serialNbr) return false;
      if (pmd.lpnManaged && !transaction.product.lpn) return false;
      if (pmd.lotManaged && !transaction.product.lotId) return false;
    }

    return true;
  };

  const handleClose = () => {
    setProduct(new Product(emptyProduct));
    setOpen(false);
  };

  const freeSpace = Number(pmd.serialManaged) + Number(pmd.lotManaged) + Number(pmd.lpnManaged) + Number(showContainer);

  const allowedStockLocations = [...stockLocations.values()].filter(item =>
    hasStockLocationRole(currentCompany.id, item.id, StockLocationRole.STOCK_LOCATION_MANAGER),
  );

  return (
    <Modal error={error} title={t().stockAdjustment.singular.label} open={open} onClose={handleClose}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: loading }),
          ProcessButtonTemplate(handleProcess, { loading, disabled: !valid() }),
        ]}
      >
        <Grid container height={'100%'} rowSpacing={1} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={1}>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  label={t().productName.singular.label}
                  onChange={() => {}}
                  value={pmd.productName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  label={t().productNumber.singular.label}
                  onChange={() => {}}
                  value={pmd.productNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={6}>
                {!product?.id ? (
                  <DropdownSelect
                    mandatory
                    values={allowedStockLocations}
                    selectedValue={stockLocations.get(transaction.product.toStockLocationId || '')}
                    toText={item => item?.name || ''}
                    label={t().destinationStockLocation.singular.label}
                    placeholder={t().destinationStockLocation.singular.label}
                    onChange={item => {
                      setTransaction(transaction.withToStockLocationId(item?.id));
                    }}
                  />
                ) : (
                  <TextInput
                    disabled
                    label={t().sourceStockLocation.singular.label}
                    onChange={() => {}}
                    value={stockLocations.get(product?.stockLocationId || '')?.name || ''}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {!product?.id ? (
                  <DropdownSelect
                    mandatory
                    values={[...inboundAllowedBins.values()].filter(
                      item =>
                        item.id !== product?.binId && item.stockLocationId === transaction.product.toStockLocationId,
                    )}
                    selectedValue={bins.get(transaction.product.toBinId || '') || null}
                    toText={item => item?.name || ''}
                    disabled={!transaction.product.toStockLocationId}
                    label={t().destinationBin.singular.label}
                    placeholder={t().destinationBin.singular.label}
                    onChange={item => {
                      setTransaction(
                        transaction.withToBinId(
                          item?.id,
                          containers.get(transaction.product.toContainerId || '')?.binId === SystemBin.id,
                        ),
                      );
                    }}
                    toElement={item => (
                      <Grid container>
                        <Grid item flexGrow={1}>
                          <p>{item.name}</p>
                        </Grid>
                        <Grid item my={'auto'}>
                          <p className='text-sm text-gray-400'>{`${toQuantityString(
                            (binProductQuantityMap.get(item.id) || 0).toString(),
                            pmd.unitOfMeasure,
                          )}`}</p>
                        </Grid>
                      </Grid>
                    )}
                  />
                ) : (
                  <TextInput
                    disabled
                    label={t().sourceBin.singular.label}
                    onChange={() => {}}
                    value={bins.get(product?.binId || '')?.name || ''}
                  />
                )}
              </Grid>
              {!product?.id && showContainer ? (
                <Grid item xs={6}>
                  <DropdownSelect
                    values={[...containers.values()].filter(container =>
                      isValidDestinationContainer({
                        container,
                        containers,
                        bins: bins,
                        stockLocationId: transaction.product.toStockLocationId || '',
                        binStatuses: binStatuses,
                        productTransactions,
                      }),
                    )}
                    selectedValue={containers.get(transaction.product.toContainerId || '') || null}
                    toText={item => item?.identifier || ''}
                    disabled={!transaction.product.toStockLocationId}
                    label={t().destinationContainer.singular.label}
                    placeholder={t().destinationContainer.singular.label}
                    onChange={item => {
                      if (item && item.binId !== SystemBin.id) {
                        transaction.product.toBinId = item.binId;
                      }
                      setTransaction(transaction.withToContainerId(item?.id));
                    }}
                    toElement={item => (
                      <Grid container>
                        <Grid item flexGrow={1}>
                          <p>{item.identifier}</p>
                        </Grid>
                        <Grid item my={'auto'}>
                          <p className='text-sm text-gray-400'>{`${toQuantityString(
                            (containerProductQuantityMap.get(item.id) || 0).toString(),
                            pmd.unitOfMeasure,
                          )}`}</p>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              ) : product.containerId ? (
                <Grid item xs={6}>
                  <TextInput
                    disabled
                    label={t().sourceContainer.singular.label}
                    onChange={() => {}}
                    value={containers.get(product?.containerId || '')?.identifier || ''}
                  />
                </Grid>
              ) : null}
              {pmd.serialManaged ? (
                <Grid item xs={6}>
                  <TextInput
                    mandatory={!product?.id}
                    disabled={!!product?.id}
                    label={t().serialNumber.singular.label}
                    placeholder={t().serialNumber.singular.label}
                    onChange={serial => setTransaction(transaction.withSerial(serial))}
                    value={product?.serial}
                  />
                </Grid>
              ) : null}
              {pmd.lpnManaged ? (
                <Grid item xs={6}>
                  <TextInput
                    mandatory={!product?.id}
                    label={t().lpn.singular.label}
                    placeholder={t().lpn.singular.label}
                    disabled={!!product?.id}
                    onChange={lpn => setTransaction(transaction.withLPN(lpn))}
                    value={product?.lpn}
                  />
                </Grid>
              ) : null}
              {pmd.lotManaged ? (
                <Grid item xs={6}>
                  {!product?.id ? (
                    <DropdownSelect
                      mandatory
                      values={[...lots.values()].filter(lot => lot.productMasterDataId === pmd.id)}
                      selectedValue={lots.get(transaction.product.lotId || '')}
                      toText={item => item?.number || ''}
                      label={t().lot.singular.label}
                      placeholder={t().lot.singular.label}
                      onChange={item => {
                        setTransaction(transaction.withLotId(item?.id));
                      }}
                    />
                  ) : (
                    <TextInput
                      disabled
                      label={t().lot.singular.label}
                      onChange={() => {}}
                      value={lots.get(product?.lotId || '')?.number || ''}
                    />
                  )}
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {product?.id ? (
                    <Grid item xs={6}>
                      <TextInput
                        disabled
                        label={t().originalQuantity.singular.label}
                        onChange={() => {}}
                        value={toQuantityString(product?.unitQuantity, pmd.unitOfMeasure)}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={6}>
                    <TextInput
                      mandatory
                      disabled={
                        ((pmd.serialManaged || (pmd.lpnManaged && !pmd.lotManaged)) && !product?.id) ||
                        !new Set(allowedStockLocations.map(item => item.id)).has(product.stockLocationId || '')
                      }
                      type='number'
                      label={product?.id ? t().newQuantity.singular.label : t().quantity.singular.label}
                      onChange={i => {
                        setTransaction(transaction.withQuantity(parseFloat(i)));
                      }}
                      value={transaction.product?.quantity?.toString()}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {product.id ? (
                <Grid item xs={12}>
                  <Checkbox
                    value={isMove}
                    disabled={!new Set(allowedStockLocations.map(item => item.id)).has(product.stockLocationId || '')}
                    onChange={setIsMove}
                    label={t().move.singular.label}
                  />
                </Grid>
              ) : null}
              {isMove ? (
                <>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <DropdownSelect
                      mandatory
                      values={allowedStockLocations}
                      selectedValue={stockLocations.get(transaction.product.toStockLocationId || '')}
                      toText={item => item?.name || ''}
                      label={t().destinationStockLocation.singular.label}
                      placeholder={t().destinationStockLocation.singular.label}
                      onChange={item => {
                        setTransaction(transaction.withToStockLocationId(item?.id));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DropdownSelect
                      mandatory
                      values={[...inboundAllowedBins.values()].filter(
                        item =>
                          item.id !== product?.binId && item.stockLocationId === transaction.product.toStockLocationId,
                      )}
                      disabled={!transaction.product.toStockLocationId}
                      selectedValue={bins.get(transaction.product.toBinId || '') || null}
                      toText={item => item?.name || ''}
                      label={t().destinationBin.singular.label}
                      placeholder={t().destinationBin.singular.label}
                      onChange={item => {
                        setTransaction(transaction.withToBinId(item?.id));
                      }}
                      toElement={item => (
                        <Grid container>
                          <Grid item flexGrow={1}>
                            <p>{item.name}</p>
                          </Grid>
                          <Grid item my={'auto'}>
                            <p className='text-sm text-gray-400'>{`${toQuantityString(
                              (binProductQuantityMap.get(item.id) || 0).toString(),
                              pmd.unitOfMeasure,
                            )}`}</p>
                          </Grid>
                        </Grid>
                      )}
                    />
                  </Grid>
                  {showContainer ? (
                    <Grid item xs={6}>
                      <DropdownSelect
                        values={[...containers.values()].filter(
                          item =>
                            item.stockLocationId === transaction.product.toStockLocationId &&
                            item.binId === transaction.product.toBinId,
                        )}
                        disabled={!transaction.product.toStockLocationId || !transaction.product.toBinId}
                        selectedValue={containers.get(transaction.product.toContainerId || '') || null}
                        toText={item => item?.identifier || ''}
                        label={t().destinationContainer.singular.label}
                        placeholder={t().destinationContainer.singular.label}
                        onChange={item => {
                          setTransaction(transaction.withToContainerId(item?.id));
                        }}
                        toElement={item => (
                          <Grid container>
                            <Grid item flexGrow={1}>
                              <p>{item.identifier}</p>
                            </Grid>
                            <Grid item my={'auto'}>
                              <p className='text-sm text-gray-400'>{`${toQuantityString(
                                (containerProductQuantityMap.get(item.id) || 0).toString(),
                                pmd.unitOfMeasure,
                              )}`}</p>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={6}>
                    <TextInput
                      disabled
                      dynamicUpdate
                      label={t().originalQuantity.singular.label}
                      onChange={() => {}}
                      value={binProductQuantityMap.get(transaction.product.toBinId || '') || '0'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      disabled
                      mandatory
                      dynamicUpdate
                      type='number'
                      label={t().newQuantity.singular.label}
                      onChange={i => {}}
                      value={(
                        parseFloat(
                          toQuantityString(
                            (binProductQuantityMap.get(transaction.product.toBinId || '') || 0)?.toString(),
                            pmd.unitOfMeasure,
                          ),
                        ) +
                        (parseFloat(toQuantityString(product.unitQuantity, pmd.unitOfMeasure)) -
                          transaction.product.quantity)
                      ).toString()}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
