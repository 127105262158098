import { Grid, Popover } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import {
  Order,
  orderSpecifierHash,
  orderSpecifierHashByValue,
  OrderType,
  orderTypeToEntitySubType,
} from '../../../../../../types/order';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { ProductTransactionSpecifiers } from '../../../../../../types/productTransaction';
import { TestIdIdentifier, testIds } from '../../../../../../util/identifiers/identifiers.util';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';
import { CustomField, CustomFieldEntityType, CustomFieldType } from '../../../../../../types/customField';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import EventIcon from '@mui/icons-material/Event';
import { ModalPane } from '../../../../../../VentoryUI/components/common/Modal/components';
import { Button } from '../../../../../../VentoryUI/components/common/Button/Button';
import DeleteButton from '../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import Table, { TableHeader } from '../../../../../../VentoryUI/components/common/Table/Table';
import { FlexPane } from '../../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import { ContainerContext } from '../../../../../../context/ContainerContext';
import { Container } from '../../../../../../types/container';
import { ContainerTypeContext } from '../../../../../../context/ContainerTypeContext';
import BoxIcon from '../../../../../../VentoryUI/icons/Box/BoxIcon';
import { toGroupedMap, toMap } from '../../../../../../util/map.util';
import { ProductContext } from '../../../../../../context/ProductContext';
import { ProductMasterDataContext } from '../../../../../../context/ProductMasterDataContext';
import PackageIcon from '../../../../../../VentoryUI/icons/Package/PackagIcon';
import TextInput from '../../../../Common/TextInput';
import { cloneDeep } from '@apollo/client/utilities';
import { getSuffix } from '../../../../../../types/unitOfMeasure';
import Checkbox from '../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import CreateOrderCreateContainerModal from './Modals/CreateOrderCreateContainerModal';
import CreateButton from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import { ChevronDownIcon } from '../../../../../../VentoryUI/icons/ChevronDown/ChevronDownIcon';
import { ChevronRightIcon } from '../../../../../../VentoryUI/icons/ChevronRight/ChevronRightIcon';
import { getAllChildContainers, getAllParentContainers } from '../../../../../../util/containers.util';

interface CreateOrderContainerPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  next?: () => void;
  back?: () => void;
  add?: () => void;
  cancel?: () => void;
  loading?: boolean;
  setError: (err: string) => void;
  modalHeight: number;
  inboundableContainers: Container[];
  outboundableContainers: Container[];
}

export default function CreateOrderContainerPane({
  order,
  setOrder,
  next,
  back,
  add,
  cancel,
  setError,
  loading = false,

  inboundableContainers,
  outboundableContainers,
}: CreateOrderContainerPaneProps) {
  const { containers, containersLoading } = useContext(ContainerContext);
  const { customFields } = useContext(CustomFieldContext);
  const { containerTypes } = useContext(ContainerTypeContext);

  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [customFieldDatePickerOpen, setCustomFieldDatePickerOpen] = useState(false);
  const [createContainerModalOpen, setCreateContainerModalOpen] = useState(false);

  const orderContainers = useMemo(() => {
    return [...order.products.values()].filter(p => !!p.containerId);
  }, [order]);

  const relevantCustomField = useMemo(() => {
    return [...customFields.values()]
      .filter(
        cf =>
          cf.entityType === CustomFieldEntityType.productTransaction &&
          cf.entitySubtype === orderTypeToEntitySubType(order.type) &&
          cf.type === CustomFieldType.date,
      )
      .at(0);
  }, [customFields]);

  const headers: TableHeader<ProductTransactionSpecifiers>[] = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: ProductTransactionSpecifiers) => {
        return (
          <div className='flex content-center'>
            <BoxIcon />
            <p className='text-[12px] text-ventory-grey-900 font-[400] pl-2'>
              {containers.get(item.containerId || '')?.identifier || t().unknownContainer.singular.label}
            </p>
          </div>
        );
      },
    },
    {
      key: 'type',
      name: t().containerType.singular.label,
      text: (item: ProductTransactionSpecifiers) => {
        const container = containers.get(item.containerId || '');
        const containerType = containerTypes.get(container?.containerTypeId || '');
        if (!containerType) return t().unknownContainerType.singular.label;
        return containerType.name;
      },
    },
    relevantCustomField
      ? {
          key: relevantCustomField.name,
          name: relevantCustomField.name,
          text: (item: ProductTransactionSpecifiers) => {
            const customFieldValue = item.customFields?.get(relevantCustomField.id)?.value;
            if (!customFieldValue) return '';
            const date = dayjs(customFieldValue);
            return date.isValid() ? date.toDate().toLocaleDateString() : '';
          },
        }
      : null,
  ].filter(e => e != null) as TableHeader<ProductTransactionSpecifiers>[];

  const availableContainers = useMemo(() => {
    switch (order.type) {
      case OrderType.inbound:
        return inboundableContainers.filter(
          container => !order.products.has(orderSpecifierHashByValue(undefined, container.id)),
        );
      case OrderType.outbound:
        const familyContainerIds = new Set(
          [...order.products.values()]
            .filter(p => p.containerId)
            .map(p => [
              ...getAllChildContainers(containers, p.containerId!),
              ...getAllParentContainers(containers, p.containerId!),
            ])
            .flat()
            .map(c => c.id),
        );

        return outboundableContainers.filter(
          container =>
            !order.products.has(orderSpecifierHashByValue(undefined, container.id)) &&
            !familyContainerIds.has(container.id),
        );
      default:
        return [];
    }
  }, [containers, order]);

  return (
    <>
      {createContainerModalOpen ? (
        <CreateOrderCreateContainerModal
          onCreated={container => {
            setOrder(
              order.withContainer(container.id, relevantCustomField?.toValue(relevantCustomField.defaultValue ?? '')),
            );
          }}
          open={createContainerModalOpen}
          setOpen={setCreateContainerModalOpen}
        />
      ) : null}

      <ModalPane
        overflow='hidden'
        testId={testIds.createOrderProductPane}
        footer={
          <Grid container>
            <Grid item>
              <Grid container columnSpacing={1}>
                {selected.size ? (
                  <Grid item>
                    <DeleteButton
                      loading={loading}
                      onClick={() => {
                        setError('');
                        const containerIds = new Set(
                          [...selected.values()]

                            .map(s => s.split('|'))
                            .filter(s => !s[0] && !!s[1])
                            .map(s => s[1]!),
                        );

                        const newProducts = [...order.products.values()].filter(t => {
                          const hash = orderSpecifierHash(t);
                          if (selected.has(hash)) return false;
                          if (t.toContainerId && containerIds.has(t.toContainerId)) return false;
                          return true;
                        });

                        order.products = toMap(newProducts, item => orderSpecifierHash(item));
                        setOrder(cloneDeep(order));
                        setSelected(new Set());
                      }}
                    />
                  </Grid>
                ) : null}
                <Grid item>
                  {relevantCustomField ? (
                    <Button
                      disabled={!selected.size || loading}
                      onClick={() => {
                        const el = document.getElementById('setCustomField');
                        if (!el) return;
                        setAnchorEl(el);
                        setCustomFieldDatePickerOpen(true);
                      }}
                      id={'setCustomField'}
                      text={relevantCustomField.name}
                      startIcon={<EventIcon />}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                {back ? (
                  <Grid item>
                    <Button onClick={back} testId={testIds.back} text={t().back.singular.label} disabled={loading} />
                  </Grid>
                ) : null}
                {next ? (
                  <Grid item>
                    <Button
                      style='secondary'
                      loading={loading}
                      disabled={
                        relevantCustomField?.mandatory &&
                        ![...order.products.values()].every(product => {
                          if (!product.customFieldValues().find(cf => cf.id === relevantCustomField.id)?.value)
                            return false;
                          return true;
                        })
                      }
                      onClick={next}
                      testId={testIds.next}
                      text={
                        !order.stockLocationId.includes('tag::') ? t().next.singular.label : t().done.singular.label
                      }
                    />
                  </Grid>
                ) : null}
                {cancel ? (
                  <Grid item>
                    <Button disabled={loading} onClick={cancel} text={t().cancel.singular.label} />
                  </Grid>
                ) : null}
                {add ? (
                  <Grid item>
                    <Button
                      style='secondary'
                      loading={loading}
                      disabled={
                        !order.products.size ||
                        (relevantCustomField?.mandatory &&
                          ![...order.products.values()].every(product => {
                            if (!product.customFieldValues().find(cf => cf.id === relevantCustomField.id)?.value)
                              return false;
                            return true;
                          }))
                      }
                      onClick={add}
                      testId={testIds.add}
                      text={t().add.singular.label}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {relevantCustomField ? (
              <Popover
                id={'setCustomField'}
                open={customFieldDatePickerOpen}
                anchorEl={anchorEl}
                onClose={() => {
                  setCustomFieldDatePickerOpen(false);
                  setAnchorEl(null);
                }}
                sx={{ marginTop: '1rem', marginLeft: '0' }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StaticDatePicker
                    displayStaticWrapperAs='desktop'
                    openTo='day'
                    minDate={new Date()}
                    value={undefined}
                    onChange={date => {
                      if (date) {
                        for (const value of selected.values()) {
                          order = order.withSpecifierCustomField(value, relevantCustomField?.toValue(date));
                        }
                      }
                      setOrder(order);
                      setCustomFieldDatePickerOpen(false);
                    }}
                  />
                </LocalizationProvider>
              </Popover>
            ) : null}
          </Grid>
        }
      >
        <FlexPane
          spacing={4}
          contentOverflow={'auto'}
          header={
            <Grid container className='content-center' gap={2}>
              <Grid item flexGrow={1}>
                <DropdownSelect
                  disabled={containersLoading || loading}
                  selectOnly
                  testId={testIds.container}
                  values={availableContainers}
                  selectedValue={null}
                  toText={item => {
                    return item.identifier || '';
                  }}
                  toElement={item => {
                    const containerType = containerTypes.get(item.containerTypeId);

                    return (
                      <Grid container>
                        <Grid item className='pr-4 h-full content-center'>
                          <BoxIcon />
                        </Grid>
                        <Grid item flexGrow={1}>
                          <Grid container>
                            <Grid item xs={12}>
                              <p className='text-sm font-bold'>{item.identifier}</p>
                            </Grid>
                            <Grid item my={'auto'} xs={12}>
                              <p className='text-sm text-gray-500'>{`${containerType?.name}`}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }}
                  onChange={item => {
                    if (!item) return;

                    setOrder(
                      order.withContainer(
                        item.id,
                        relevantCustomField?.toValue(relevantCustomField.defaultValue ?? ''),
                      ),
                    );
                  }}
                  placeholder={t().selectContainer.singular.upper}
                />
              </Grid>
              {order.type === OrderType.inbound ? (
                <Grid item className='content-center'>
                  <div className='mt-1'>
                    <CreateButton onClick={() => setCreateContainerModalOpen(true)} />
                  </div>
                  {/* <div className='p-2 rounded border border-slate-300 '>
                    <AddIcon onClick={() => setCreateContainerModalOpen(true)} />
                  </div> */}
                </Grid>
              ) : null}
            </Grid>
          }
          content={
            order.type === OrderType.outbound ? (
              <Table<ProductTransactionSpecifiers>
                modal
                uniqueIdentifier={item => orderSpecifierHash(item)}
                loading={containersLoading}
                onSelected={items => setSelected(new Set(items))}
                selectedValues={selected}
                items={orderContainers}
                headers={headers}
              />
            ) : (
              <InboundContainerList
                order={order}
                setOrder={setOrder}
                relevantCustomField={relevantCustomField}
                selected={selected}
                setSelected={setSelected}
              />
            )
          }
          testId={testIds.createOrderProductPane}
        />
      </ModalPane>
    </>
  );
}

interface InboundContainerListProps {
  order: Order;
  setOrder: (order: Order) => void;
  relevantCustomField: CustomField | undefined;
  selected: Set<string>;
  setSelected: (selected: Set<string>) => void;
}

function InboundContainerList({
  order,
  setOrder,
  relevantCustomField,
  selected,
  setSelected,
}: InboundContainerListProps) {
  const containerSpecifiers = [...order.products.values()].filter(p => p.containerId);

  const byToContainerId = useMemo(() => {
    return toGroupedMap([...order.products.values()], 'toContainerId');
  }, [order]);

  return (
    <div className='grid gap-y-2'>
      {containerSpecifiers.map(specifier => {
        return (
          <InboundContainerItem
            order={order}
            setOrder={setOrder}
            relevantCustomField={relevantCustomField}
            specifier={specifier}
            specifiers={byToContainerId.get(specifier.containerId || '') || []}
            selected={selected}
            setSelected={setSelected}
          />
        );
      })}
    </div>
  );
}

interface InboundContainerItemProps {
  order: Order;
  setOrder: (order: Order) => void;
  relevantCustomField: CustomField | undefined;
  specifier: ProductTransactionSpecifiers;
  specifiers: ProductTransactionSpecifiers[];
  selected: Set<string>;
  setSelected: (selected: Set<string>) => void;
}

function InboundContainerItem({
  order,
  setOrder,
  relevantCustomField,
  specifier,
  specifiers,
  selected,
  setSelected,
}: InboundContainerItemProps) {
  const containerId = specifier.containerId;
  if (!containerId) return null;
  const { containers } = useContext(ContainerContext);

  const [open, setOpen] = useState(true);

  const container = containers.get(containerId);
  if (!container) return null;

  const specifiersByPmdId = toGroupedMap(specifiers, 'pmdId');

  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { products, productsLoading } = useContext(ProductContext);

  const headers: TableHeader<ProductTransactionSpecifiers>[] = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: ProductTransactionSpecifiers) => {
        return (
          <div className='flex content-center'>
            <PackageIcon />
            <p className='text-[12px] text-ventory-grey-900 font-[400] pl-2'>
              {productMasterData.get(item.pmdId || '')?.productName || t().unknownProductReference.singular.label}
            </p>
          </div>
        );
      },
    },
    relevantCustomField
      ? {
          key: relevantCustomField.name,
          name: relevantCustomField.name,
          text: (item: ProductTransactionSpecifiers) => {
            const customFieldValue = item.customFields?.get(relevantCustomField.id)?.value;
            if (!customFieldValue) return '';
            const date = dayjs(customFieldValue);
            return date.isValid() ? date.toDate().toLocaleDateString() : '';
          },
        }
      : null,
    {
      key: 'quantity',
      name: t().quantity.singular.label,
      text: (item: ProductTransactionSpecifiers) => (
        <Grid container className='h-7' justifyContent={'flex-end'}>
          <Grid item xs={6} marginY={'auto'} justifyContent={'flex-end'} mr={0.1}>
            <TextInput
              disabled={!!item.containerId}
              onChange={v => {
                const foundItem = order.products.get(orderSpecifierHash(item) || '');
                if (!foundItem) return;
                foundItem.quantity = parseFloat(v) || 0;
                setOrder(cloneDeep(order));
              }}
              value={item.quantity.toString()}
              dynamicUpdate
              testId={new TestIdIdentifier(`quantityInput${orderSpecifierHash(item)}`)}
              heightClass='h-7'
              type='number'
              // Making the suffix a space by default so the text input will display the suffix version even if the UnitOfMeasure is quantity.
              suffix={getSuffix(productMasterData.get(item.pmdId || '')?.unitOfMeasure) || ' '}
            />
          </Grid>
        </Grid>
      ),
      sortValue: (item: ProductTransactionSpecifiers) => item.quantity,
    },
  ].filter(e => e != null) as TableHeader<ProductTransactionSpecifiers>[];

  const hash = orderSpecifierHash(specifier);

  return (
    <Grid container className='p-4 border border-slate-300 rounded' gap={1}>
      <Grid item xs={12} onClick={() => setOpen(!open)}>
        <Grid container columnGap={1}>
          <Grid item>
            <Checkbox
              value={selected.has(hash)}
              onChange={value => {
                if (value) {
                  selected.add(hash);
                } else {
                  selected.delete(hash);
                }
                setSelected(new Set(selected));
              }}
            />
          </Grid>
          <Grid item className='content-center'>
            <BoxIcon />
          </Grid>
          <Grid item>
            <p className='text-ventory-grey-900 text-[14px]'>{container.identifier}</p>
          </Grid>
          <Grid item flexGrow={1}></Grid>
          <Grid item className='content-center'>
            <p className='text-ventory-grey-900 text-[14px] mr-2'>
              {specifiersByPmdId.size}{' '}
              {specifiersByPmdId.size === 1 ? t().product.singular.label : t().product.plural.label}
            </p>
          </Grid>
          <Grid item className='content-center flex'>
            {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Grid>
        </Grid>
      </Grid>

      {open ? (
        <>
          <Grid item xs={12}>
            <DropdownSelect
              disabled={productMasterDataLoading || productsLoading}
              selectOnly
              testId={testIds.product}
              values={[...productMasterData.values()].filter(pmd => !specifiersByPmdId.has(pmd.id))}
              selectedValue={null}
              toText={item => {
                return `${item?.productName} - ${item.productNumber}` || '';
              }}
              toElement={item => {
                // const quantity = quantityForProduct.get(item.id);
                return (
                  <Grid container>
                    <Grid item className='pr-4 h-full content-center'>
                      <PackageIcon />
                    </Grid>
                    <Grid item flexGrow={1}>
                      <Grid container>
                        <Grid item xs={12}>
                          <p className='text-sm font-bold'>{item.productName}</p>
                        </Grid>
                        <Grid item my={'auto'} xs={12}>
                          <p className='text-sm text-gray-500'>{item.productNumber}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }}
              onChange={item => {
                if (!item) return;
                setOrder(
                  order.withProduct(
                    item.id,
                    relevantCustomField?.toValue(relevantCustomField.defaultValue ?? ''),
                    containerId,
                  ),
                );
              }}
              placeholder={t().selectProduct.singular.upper}
            />
          </Grid>

          {specifiers.length ? (
            <Grid item xs={12}>
              <Table
                modal
                uniqueIdentifier={item => orderSpecifierHash(item)}
                selectedValues={selected}
                onSelected={setSelected}
                items={specifiers}
                headers={headers}
                customPagination={5}
              />
            </Grid>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
}
