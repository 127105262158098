import { Divider, Grid } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import {
  ProductTransaction,
  ProductTransactionParentType,
  ProductTransactionStatus,
  ProductTransactionType,
  productTransactionTypeToCustomFieldEntitySubType,
} from '../../../../../../types/productTransaction';
import TextInput from '../../../../Common/TextInput';
import { ProductMasterDataContext } from '../../../../../../context/ProductMasterDataContext';
import { LotContext } from '../../../../../../context/LotContext';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { BinContext } from '../../../../../../context/BinContext';
import { useMutation } from '@apollo/client';
import {
  ProcessProductTransactionsResponse,
  ProcessProductTransactionsVariables,
  ProductTransactionMutations,
  UpdateProductTransactionCustomFieldsResponse,
  UpdateProductTransactionCustomFieldsVariables,
  RollbackProductTransactionsResponse,
  RollbackProductTransactionsVariables,
} from '../../../../../../graphql/productTransaction.graphql';
import { ProductContext } from '../../../../../../context/ProductContext';
import ErrorBox from '../../../../Common/ErrorBox';
import { errorCodeToText } from '../../../../../../util/error.util';
import { BinStatusContext } from '../../../../../../context/BinStatusContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ProductTransactionContext } from '../../../../../../context/ProductTransactionContext';
import { StockLocationRoleAssignmentContext } from '../../../../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../../../../types/stockLocationRoleAssignment';
import { getSuffix, toQuantityString } from '../../../../../../types/unitOfMeasure';
import { classes, testIds } from '../../../../../../util/identifiers/identifiers.util';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';
import { CustomFieldEntityType } from '../../../../../../types/customField';
import { OrderContext } from '../../../../../../context/OrderContext';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { UpdateButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/UpdateButton';
import { CancelButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { ProcessButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/ProcessButton';
import { FooterButton } from '../../../../../../VentoryUI/components/common/Button';
import CustomFieldInput from '../../../../Common/CustomFieldInput';
import { OrderParentType } from '../../../../../../types/order';
import { StockLocationContext } from '../../../../../../context/StockLocationContext';

import { ContainerContext } from '../../../../../../context/ContainerContext';
import { FlexPane } from '../../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../../../../VentoryUI/components/common/Table/Table';

import {
  getAllChildContainers,
  getAllChildProductTransactions,
  isValidDestinationContainer,
  isValidSourceContainer,
} from '../../../../../../util/containers.util';
import { productTransactionTableHeaders } from '../../../../Common/ProductTransactionTable';
import { ContainerTypeContext } from '../../../../../../context/ContainerTypeContext';
import { UserContext } from '../../../../../../context/UserContext';
import {
  ProductTransactionTableSettings,
  ProductTransactionColumn,
} from '../../../../../../types/productTransactionTableSettings';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { Container } from '../../../../../../types/container';
import { toMap } from '../../../../../../util/map.util';
import { SystemBin } from '../../../../../../types/bin';
import Checkbox from '../../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { cloneDeep } from '@apollo/client/utilities';
import { Product } from '../../../../../../types/product';
import CreateOrderCreateContainerModal from './Modals/CreateOrderCreateContainerModal';
import { AddIcon } from '../../../../../../VentoryUI/icons/Add/AddIcon';

interface ProcessOrderTransactionInfoPaneProps {
  transaction: ProductTransaction;
  setTransaction: (transaction: ProductTransaction) => void;
  setError: (error: string) => void;
  onClose: () => void;
  disabled?: boolean;
  parentInfo?: boolean;
}

export default function ProcessOrderTransactionInfoPane({
  transaction,
  setTransaction,
  setError,
  onClose,
  disabled,
  parentInfo = false,
}: ProcessOrderTransactionInfoPaneProps) {
  dayjs.extend(customParseFormat);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { orders } = useContext(OrderContext);
  const { lots } = useContext(LotContext);
  const { bins } = useContext(BinContext);
  const { containers } = useContext(ContainerContext);
  const { containerTypes } = useContext(ContainerTypeContext);
  const { products } = useContext(ProductContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { productTransactions, setProductTransactions } = useContext(ProductTransactionContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { customFields } = useContext(CustomFieldContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { companyUsers } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);

  const pmd = productMasterData.get(transaction.product.pmdId || '');
  const container = containers.get(transaction.product.containerId || '');
  if (!pmd && !container) return null;

  const [createContainerModalOpen, setCreateContainerModalOpen] = useState(false);

  const showContainer = useMemo(() => currentCompany.settings.featureToggles.containers.containers, [currentCompany]);

  const isDefined = useMemo(() => {
    if (transaction.product.fromProductId && transaction.containerTransactionId) return true;
    return false;
  }, []);

  const containerTransaction = productTransactions.get(transaction.containerTransactionId || 'NONE');

  useEffect(() => {
    if (containerTransaction?.product.toBinId) {
      setTransaction(transaction.withToBinId(containerTransaction.product.toBinId, true));
    }
  }, []);

  const [process, { loading }] = useMutation<ProcessProductTransactionsResponse, ProcessProductTransactionsVariables>(
    ProductTransactionMutations.process,
    {
      onCompleted: res => {
        const transaction = res.processProductTransaction[0];
        productTransactions.set(transaction.id, new ProductTransaction(transaction));
        setProductTransactions(new Map(productTransactions));
        onClose();
      },
      onError: err => setError(errorCodeToText(err.message)),
    },
  );

  const handleProcess = async () => {
    try {
      await process({
        variables: {
          productTransactions: [transaction.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const [update, { loading: updateLoading }] = useMutation<
    UpdateProductTransactionCustomFieldsResponse,
    UpdateProductTransactionCustomFieldsVariables
  >(ProductTransactionMutations.updateCustomFields, {
    onCompleted: res => {
      const transaction = res.updateProductTransactionCustomFields[0];
      productTransactions.set(transaction.id, new ProductTransaction(transaction));
      setProductTransactions(new Map(productTransactions));
      onClose();
    },
  });

  const handleUpdate = async () => {
    try {
      await update({ variables: { productTransactions: [transaction.forUpdate()] } });
    } catch (e) {
      setError(String(e));
    }
  };

  const [rollback, { loading: rollbackLoading }] = useMutation<
    RollbackProductTransactionsResponse,
    RollbackProductTransactionsVariables
  >(ProductTransactionMutations.rollback, {
    onCompleted: res => {
      const transaction = res.rollbackProductTransaction[0];
      productTransactions.set(transaction.id, transaction);
      setProductTransactions(new Map(productTransactions));
      onClose();
    },
    onError: err => setError(errorCodeToText(err.message)),
  });

  const handleCancel = async () => {
    try {
      transaction.status = ProductTransactionStatus.cancelled;

      await process({
        variables: {
          productTransactions: [transaction.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleRollback = async () => {
    try {
      transaction.status = ProductTransactionStatus.rollback;

      await rollback({
        variables: {
          productTransactions: [transaction.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const orderContainers = useMemo(() => {
    const orderTransactions = [...productTransactions.values()].filter(pt => pt.parentId === transaction.parentId);
    const childContainers = !container
      ? new Map<string, Container>()
      : toMap([container, ...getAllChildContainers(containers, transaction.product.containerId || 'NONE')], 'id');

    return orderTransactions
      .filter(
        ot =>
          ot.status === ProductTransactionStatus.created &&
          ot.product.containerId &&
          !childContainers.has(ot.product.containerId),
      )
      .map(ot => containers.get(ot.product.containerId || 'NONE'))
      .filter(c => !!c)
      .map(c => c!);
  }, [productTransactions, containers]);

  const relevantCustomFields = useMemo(() => {
    return [...customFields.values()].filter(
      cf =>
        cf.entityType === CustomFieldEntityType.productTransaction &&
        cf.entitySubtype === productTransactionTypeToCustomFieldEntitySubType(transaction.type),
    );
  }, [customFields]);

  const stockLocationId =
    transaction.type === ProductTransactionType.inbound || transaction.type === ProductTransactionType.replenish
      ? transaction.product.toStockLocationId
      : transaction.product.fromStockLocationId;
  if (!stockLocationId) return null;

  disabled ||= !hasStockLocationRole(transaction.companyId, stockLocationId, StockLocationRole.STOCK_LOCATION_USER);

  const binsForProduct = useMemo(() => {
    const map = new Map<string, number>();
    if (!pmd) return map;
    [...(products.get(pmd.id)?.values() || [])]
      .filter(item => !transaction.product.lotId || item.lotId === transaction.product.lotId)
      .forEach(p => {
        const existing = map.get(p.binId);
        const quantity = parseFloat(p.unitQuantity || '0') || 0;

        map.set(p.binId, existing ? existing + quantity : quantity);
      });
    return map;
  }, [products, pmd]);

  const containersForProduct = useMemo(() => {
    const map = new Map<string, number>();
    if (!pmd) return map;
    [...(products.get(pmd.id)?.values() || [])].forEach(p => {
      map.set(p.containerId || p.binId, parseFloat(p.unitQuantity || '0') || 0);
    });
    return map;
  }, [products, pmd]);

  const inboundAllowedBins = new Set<string>(
    [...bins.values()]
      .filter(bin => binStatuses.get(bins.get(bin.id)?.binStatusId || '')?.inboundAllowed)
      .map(bin => bin.id),
  );

  const outboundAllowedBins = new Set<string>(
    [...binsForProduct.keys()].filter(binId => binStatuses.get(bins.get(binId)?.binStatusId || '')?.outboundAllowed),
  );

  const resetProduct = () => {
    transaction.product.fromProductId = undefined;
    transaction.product.fromBinId = undefined;
    transaction.product.fromContainerId = undefined;
    transaction.product.lpn = undefined;
    transaction.product.serialNbr = undefined;
    transaction.product.lotId = undefined;
    setTransaction(cloneDeep(transaction));
  };

  const determineProduct = (productId?: string) => {
    const flatProducts = [...products.values()].map(ps => [...ps.values()]).flat();

    const find = () => {
      for (const product of flatProducts) {
        if (productId && product.id === productId) return product;

        if (product.productMasterDataId != transaction.product.pmdId) continue;

        // TODO: We should start looking at defaulting to undefined or null
        if (product.lpn && product.lpn === transaction.product.lpn) return product;
        if (product.serial && product.serial === transaction.product.serialNbr) return product;

        if (product.stockLocationId != transaction.product.fromStockLocationId) continue;
        if (product.binId != transaction.product.fromBinId) continue;
        if (product.containerId != transaction.product.fromContainerId) continue;
        if (product.lotId != transaction.product.lotId) continue;

        return product;
      }
    };

    const product = find();

    // Defaulting to 1 for serials
    if (!product) return setTransaction(transaction.withFromProductId(undefined).withProcessedQuantity(1));

    setTransaction(
      transaction
        .withFromProduct(product)
        .withProcessedQuantity(Math.min(transaction.product.quantity, parseFloat(product.quantity))),
    );
  };

  const buttons = useMemo(() => {
    const shown: FooterButton[] = [
      {
        align: 'left',
        text:
          transaction.status !== ProductTransactionStatus.created
            ? t().rollbackTransaction.singular.label
            : t().cancelTransaction.singular.label,
        testId: testIds.cancelTransaction,
        disabled:
          transaction.status === ProductTransactionStatus.cancelled ||
          transaction.status === ProductTransactionStatus.failed ||
          transaction.status === ProductTransactionStatus.rollback ||
          rollbackLoading ||
          transaction.externalIdentifier?.type === 'purchaseOrder' ||
          (transaction.status !== ProductTransactionStatus.created && !!transaction.containerTransactionId),
        loading: loading || rollbackLoading,
        onClick: transaction.status === ProductTransactionStatus.created ? handleCancel : handleRollback,
      },
      CancelButtonTemplate(onClose, {
        style: disabled ? 'secondary' : 'primary',
      }),
    ];

    if (!disabled) {
      if (relevantCustomFields.length && transaction.externalIdentifier?.type !== 'purchaseOrder') {
        shown.push(UpdateButtonTemplate(handleUpdate, { loading: loading }));
      }

      const isDisabled = () => {
        if (disabled) return true;

        if (
          !transaction.product.processedQuantity ||
          transaction.product.processedQuantity === 0 ||
          isNaN(transaction.product.processedQuantity)
        ) {
          return true;
        }

        if (pmd?.serialManaged && !transaction.product.serialNbr) return true;
        if (pmd?.lpnManaged && !transaction.product.lpn) return true;
        if (pmd?.lotManaged && !transaction.product.lotId) return true;

        if (transaction.type === ProductTransactionType.inbound && !transaction.product.toBinId) return true;
        if (transaction.type === ProductTransactionType.outbound && !transaction.product.fromBinId) return true;

        if (!new Set([ProductTransactionStatus.created, ProductTransactionStatus.picked]).has(transaction.status)) {
          return true;
        }

        if (transaction.product.containerId) return false;

        if (transaction.type === ProductTransactionType.outbound || transaction.type === ProductTransactionType.move) {
          const flatProducts = [...products.values()].map(ps => [...ps.values()]).flat();
          const existing = flatProducts.find(p => p.id === transaction.product.fromProductId);

          if (!existing) return true;
          if (parseFloat(existing.quantity) < transaction.product.processedQuantity) return true;
        }

        return false;
      };

      if (
        transaction.status === ProductTransactionStatus.created ||
        transaction.status === ProductTransactionStatus.picked
      ) {
        shown.push(
          ProcessButtonTemplate(handleProcess, {
            disabled: isDisabled(),
            text:
              currentCompany.settings.featureToggles.orders.picking &&
              transaction.status === ProductTransactionStatus.created &&
              transaction.type === ProductTransactionType.outbound
                ? t().pick.singular.label
                : t().process.singular.label,
            loading: loading,
          }),
        );
      }
    }

    return shown;
  }, [disabled, transaction, loading, rollbackLoading]);

  return (
    <ModalPane footerButtons={buttons}>
      <FlexPane
        header={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ErrorBox
                error={transaction.reasonForFailure ? `Reason for failure: ${transaction.reasonForFailure}` : ''}
              />
            </Grid>
            {pmd ? (
              <>
                <Grid item xs={6}>
                  <TextInput
                    disabled
                    value={pmd.productName}
                    label={t().productName.singular.label}
                    onChange={() => {}}
                    testId={testIds.productName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled
                    value={pmd.productNumber}
                    label={t().productNumber.singular.label}
                    onChange={() => {}}
                    testId={testIds.productNumber}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={6}>
                <TextInput
                  disabled
                  value={container?.identifier}
                  label={t().container.singular.label}
                  onChange={() => {}}
                  testId={testIds.productName}
                />
              </Grid>
            )}
            {transaction.product.fromStockLocationId ? (
              <Grid item xs={6}>
                <TextInput
                  label={t().sourceStockLocation.singular.label}
                  disabled
                  value={stockLocations.get(transaction.product.fromStockLocationId)?.name || ''}
                  onChange={() => {}}
                />
              </Grid>
            ) : null}
            {transaction.product.toStockLocationId ? (
              <Grid item xs={6}>
                <TextInput
                  label={t().destinationStockLocation.singular.label}
                  disabled
                  value={stockLocations.get(transaction.product.toStockLocationId)?.name || ''}
                  onChange={() => {}}
                />
              </Grid>
            ) : null}
            {parentInfo && transaction.parentId && transaction.parentType === ProductTransactionParentType.order ? (
              <Grid item xs={6}>
                <TextInput
                  label={t().order.singular.label}
                  disabled
                  value={orders.get(transaction.parentId)?.number || ''}
                  onChange={() => {}}
                />
              </Grid>
            ) : null}
            {transaction.pickedBy && transaction.pickedAt ? (
              <Grid item xs={12}>
                <Grid container columnGap={1}>
                  <Grid item xs={6}>
                    <TextInput
                      label={t().pickedBy.singular.label}
                      disabled
                      value={companyUsers.get(transaction.pickedBy)?.email}
                      onChange={() => {}}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      label={t().pickedAt.singular.label}
                      disabled
                      value={`${new Date(transaction.pickedAt).toLocaleDateString()} - ${new Date(
                        transaction.pickedAt,
                      ).toLocaleTimeString()}`}
                      onChange={() => {}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            {transaction.type === ProductTransactionType.outbound ||
            transaction.type === ProductTransactionType.move ? (
              <>
                <Grid item xs={6}>
                  <DropdownSelect
                    testId={testIds.bin}
                    disabled={
                      transaction.status !== ProductTransactionStatus.created ||
                      disabled ||
                      orders.get(transaction.parentId || '')?.parentType === OrderParentType.task ||
                      !!container ||
                      !!transaction.containerTransactionId ||
                      pmd?.serialManaged ||
                      pmd?.lpnManaged
                    }
                    placeholder={t().sourceBin.singular.label}
                    label={t().sourceBin.singular.label}
                    values={[...bins.values()]
                      .filter(
                        b =>
                          b.stockLocationId === transaction.product.fromStockLocationId &&
                          binsForProduct.has(b.id) &&
                          outboundAllowedBins.has(b.id),
                      )
                      .sort((a, b) => (binsForProduct.get(b.id) || 0) - (binsForProduct.get(a.id) || 0))}
                    selectedValue={bins.get(transaction.product.fromBinId || '') || null}
                    toText={item => item.name}
                    toElement={item => (
                      <Grid container>
                        <Grid item flexGrow={1}>
                          <p>{item.name}</p>
                        </Grid>
                        {pmd ? (
                          <Grid item my={'auto'}>
                            <p className='text-sm text-gray-400'>{`${toQuantityString(
                              (binsForProduct.get(item.id) || 0).toString(),
                              pmd.unitOfMeasure,
                            )}`}</p>
                          </Grid>
                        ) : null}
                      </Grid>
                    )}
                    onChange={bin => {
                      transaction.product.fromBinId = bin?.id;
                      transaction.product.fromContainerId = undefined;
                      determineProduct();
                    }}
                  />
                </Grid>
                {showContainer ? (
                  <Grid item xs={6}>
                    <DropdownSelect
                      testId={testIds.container}
                      leading={
                        containersForProduct.has(transaction.product.fromBinId ?? '') ? (
                          <Grid
                            item
                            xs={12}
                            onClick={event => {
                              setTransaction(transaction.withFromContainerId(undefined).withProcessedQuantity(0));
                            }}
                            className={`px-3 py-1 cursor-not-allowed`}
                          >
                            <Grid container marginY={'auto'}>
                              <Grid container>
                                <Grid item flexGrow={1}>
                                  <p>{'None'}</p>
                                </Grid>
                                {pmd ? (
                                  <Grid item my={'auto'}>
                                    <p className='text-sm text-gray-400'>{`${toQuantityString(
                                      (containersForProduct.get(transaction.product.fromBinId || '') || 0).toString(),
                                      pmd.unitOfMeasure,
                                    )}`}</p>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : undefined
                      }
                      disabled={
                        transaction.status !== ProductTransactionStatus.created ||
                        disabled ||
                        (orders.get(transaction.parentId || '')?.parentType === OrderParentType.task &&
                          transaction.type === ProductTransactionType.outbound) ||
                        !!container ||
                        !!transaction.containerTransactionId ||
                        pmd?.serialManaged ||
                        pmd?.lpnManaged
                      }
                      placeholder={t().sourceContainer.singular.label}
                      label={t().sourceContainer.singular.label}
                      values={[...containers.values()]
                        .filter(c =>
                          isValidSourceContainer({
                            container: c,
                            stockLocationId: transaction.product.fromStockLocationId || '',
                            bins,
                            binStatuses,
                            containers,
                            productTransactions,
                            transaction,
                            binId: transaction.product.fromBinId,
                          }),
                        )
                        .sort((a, b) => (containersForProduct.get(b.id) || 0) - (containersForProduct.get(a.id) || 0))}
                      selectedValue={containers.get(transaction.product.fromContainerId || '') || null}
                      onChange={container => {
                        transaction.product.fromContainerId = container?.id;
                        if (container && container.binId !== SystemBin.id) {
                          transaction.product.fromBinId = container.binId;
                        }

                        determineProduct();
                      }}
                      toText={item => `${item.identifier}`}
                      toElement={item => (
                        <Grid container>
                          <Grid item flexGrow={1}>
                            <p>{item.identifier}</p>
                          </Grid>
                          {pmd ? (
                            <Grid item my={'auto'}>
                              <p className='text-sm text-gray-400'>{`${toQuantityString(
                                (containersForProduct.get(item.id) || 0).toString(),
                                pmd.unitOfMeasure,
                              )}`}</p>
                            </Grid>
                          ) : null}
                        </Grid>
                      )}
                    />
                  </Grid>
                ) : null}
                {transaction.type === ProductTransactionType.outbound &&
                orderContainers.length &&
                !transaction.containerTransactionId &&
                showContainer ? (
                  <Grid item xs={6}>
                    <DropdownSelect<Container>
                      disabled={disabled}
                      values={orderContainers}
                      placeholder={t().destinationContainer.singular.label}
                      label={t().destinationContainer.singular.label}
                      selectedValue={containers.get(transaction.product.toContainerId || 'NONE') || null}
                      onChange={value => {
                        transaction.product.toStockLocationId = value?.stockLocationId;
                        transaction.product.toBinId = value?.binId;
                        setTransaction(transaction.withToContainerId(value?.id));
                      }}
                      toText={value => value?.identifier}
                    />
                  </Grid>
                ) : null}
              </>
            ) : null}
            {transaction.type === ProductTransactionType.inbound || transaction.type === ProductTransactionType.move ? (
              <>
                <Grid item xs={6}>
                  <DropdownSelect
                    testId={testIds.bin}
                    mandatory
                    disabled={
                      transaction.status !== ProductTransactionStatus.created ||
                      disabled ||
                      (orders.get(transaction.parentId || '')?.parentType === OrderParentType.task &&
                        transaction.type === ProductTransactionType.inbound) ||
                      !!containerTransaction?.product.toBinId
                    }
                    placeholder={t().destinationBin.singular.label}
                    label={t().destinationBin.singular.label}
                    values={[...bins.values()]
                      .filter(
                        b =>
                          b.stockLocationId === transaction.product.toStockLocationId && inboundAllowedBins.has(b.id),
                      )
                      .sort((a, b) => (binsForProduct.get(b.id) || 0) - (binsForProduct.get(a.id) || 0))}
                    selectedValue={bins.get(transaction.product.toBinId || '') || null}
                    toText={item => `${item.name}`}
                    toElement={item => (
                      <Grid container>
                        <Grid item flexGrow={1}>
                          <p>{item.name}</p>
                        </Grid>
                        {pmd ? (
                          <Grid item my={'auto'}>
                            <p className='text-sm text-gray-400'>{`${toQuantityString(
                              (binsForProduct.get(item.id) || 0).toString(),
                              pmd.unitOfMeasure,
                            )}`}</p>
                          </Grid>
                        ) : null}
                      </Grid>
                    )}
                    onChange={bin =>
                      setTransaction(
                        transaction.withToBinId(
                          bin?.id,
                          containers.get(transaction.product.toContainerId || '')?.binId === SystemBin.id,
                        ),
                      )
                    }
                  />
                </Grid>

                <CreateOrderCreateContainerModal
                  open={createContainerModalOpen}
                  setOpen={setCreateContainerModalOpen}
                  onCreated={c => setTransaction(transaction.withToContainerId(c?.id))}
                />

                {showContainer ? (
                  <Grid item xs={6}>
                    <DropdownSelect
                      trailing={
                        <Grid
                          item
                          xs={12}
                          className={`${classes.dropdownContentItem.name} px-3 py-1 hover:backdrop-brightness-95 `}
                          onClick={() => setCreateContainerModalOpen(true)}
                        >
                          <Grid container marginY={'auto'}>
                            <Grid item marginY={'auto'} pr={1}>
                              <AddIcon />
                            </Grid>
                            <Grid item marginY={'auto'}>
                              <p>Create new container</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                      disabled={
                        transaction.status !== ProductTransactionStatus.created ||
                        disabled ||
                        (orders.get(transaction.parentId || '')?.parentType === OrderParentType.task &&
                          transaction.type === ProductTransactionType.inbound) ||
                        !!transaction.containerTransactionId
                      }
                      placeholder={t().destinationContainer.singular.label}
                      label={t().destinationContainer.singular.label}
                      values={[...containers.values()]
                        .filter(c =>
                          isValidDestinationContainer({
                            container: c,
                            stockLocationId: transaction.product.toStockLocationId || '',
                            binStatuses,
                            bins,
                            binId: transaction.product.toBinId,
                            productTransactions,
                            containers,
                            transaction,
                          }),
                        )
                        .sort((a, b) => (containersForProduct.get(b.id) || 0) - (containersForProduct.get(a.id) || 0))}
                      selectedValue={containers.get(transaction.product.toContainerId || '') || null}
                      onChange={container => {
                        if (container && container.binId !== SystemBin.id) {
                          transaction.product.toBinId = container.binId;
                        }
                        setTransaction(transaction.withToContainerId(container?.id));
                      }}
                      toText={item => `${item.identifier}`}
                      toElement={item => (
                        <Grid container>
                          <Grid item flexGrow={1}>
                            <p>{item.identifier}</p>
                          </Grid>
                          {pmd ? (
                            <Grid item my={'auto'}>
                              <p className='text-sm text-gray-400'>{`${toQuantityString(
                                (containersForProduct.get(item.id) || 0).toString(),
                                pmd.unitOfMeasure,
                              )}`}</p>
                            </Grid>
                          ) : null}
                        </Grid>
                      )}
                    />
                  </Grid>
                ) : null}
              </>
            ) : null}
            {transaction.type === ProductTransactionType.inbound &&
            transaction.status === ProductTransactionStatus.created &&
            transaction.product.toContainerId &&
            [...productTransactions.values()].find(
              el =>
                el.parentId === transaction.parentId && el.product.containerId === transaction.product.toContainerId,
            )?.status === ProductTransactionStatus.created ? (
              <Grid item xs={12}>
                <Checkbox
                  value={transaction.processChildren}
                  onChange={value => setTransaction(transaction.withProcessChildren(value))}
                  label={t().processChildrenQuestion.singular.upper}
                />
              </Grid>
            ) : null}
            {pmd?.lpnManaged ? (
              <Grid item xs={6}>
                {transaction.type !== ProductTransactionType.inbound ? (
                  <DropdownSelect<Product>
                    mandatory
                    disabled={disabled}
                    label={t().lpn.singular.label}
                    placeholder={t().lpn.singular.label}
                    values={[...(products.get(transaction.product.pmdId || '')?.values() || [])].filter(el => el.lpn)}
                    onChange={selected => {
                      if (!selected) return resetProduct();
                      determineProduct(selected.id);
                    }}
                    selectedValue={
                      products
                        .get(transaction.product.pmdId || 'NONE')
                        ?.get(transaction.product.fromProductId || 'NONE') || null
                    }
                    toText={product => product.lpn || ''}
                  />
                ) : (
                  <TextInput
                    disabled={transaction.status !== ProductTransactionStatus.created || disabled || isDefined}
                    mandatory
                    label={t().lpn.singular.label}
                    placeholder={t().lpn.singular.label}
                    onChange={v => setTransaction(transaction.withLPN(v))}
                    testId={testIds.lpn}
                    value={transaction.product.lpn}
                  />
                )}
              </Grid>
            ) : null}
            {pmd?.serialManaged ? (
              <Grid item xs={6}>
                {transaction.type !== ProductTransactionType.inbound ? (
                  <DropdownSelect<Product>
                    mandatory
                    disabled={disabled || pmd.lpnManaged}
                    label={t().serial.singular.label}
                    placeholder={t().serial.singular.label}
                    values={[...(products.get(transaction.product.pmdId || '')?.values() || [])].filter(
                      el => el.serial,
                    )}
                    onChange={selected => {
                      if (!selected) return resetProduct();
                      determineProduct(selected.id);
                    }}
                    selectedValue={
                      products
                        .get(transaction.product.pmdId || 'NONE')
                        ?.get(transaction.product.fromProductId || 'NONE') || null
                    }
                    toText={product => product.serial || ''}
                  />
                ) : (
                  <TextInput
                    disabled={transaction.status !== ProductTransactionStatus.created || disabled || isDefined}
                    dynamicUpdate
                    mandatory
                    label={t().serialNumber.singular.label}
                    placeholder={t().serialNumber.singular.label}
                    onChange={v => setTransaction(transaction.withSerial(v))}
                    testId={testIds.serial}
                    value={transaction.product.serialNbr}
                  />
                )}
              </Grid>
            ) : null}

            {pmd?.lotManaged ? (
              <Grid item xs={6}>
                <DropdownSelect
                  mandatory
                  disabled={
                    transaction.status !== ProductTransactionStatus.created ||
                    disabled ||
                    isDefined ||
                    (transaction.type !== ProductTransactionType.inbound && pmd.lpnManaged)
                  }
                  placeholder={t().lotNumber.singular.label}
                  label={t().lotNumber.singular.label}
                  values={[...lots.values()].filter(lot => lot.productMasterDataId === transaction.product.pmdId)}
                  selectedValue={lots.get(transaction.product.lotId || '') || null}
                  toText={item => item.number}
                  onChange={v => {
                    setTransaction(transaction.withLotId(v?.id));
                    if (transaction.type !== ProductTransactionType.inbound) determineProduct();
                  }}
                  testId={testIds.lot}
                  toElement={item => {
                    const existingLotProducts = [...(products.get(transaction.product.pmdId!)?.values() ?? [])].filter(
                      element =>
                        element.lotId === item.id &&
                        element.stockLocationId ==
                          (transaction.product.fromStockLocationId || transaction.product.toStockLocationId),
                    );
                    let quantity;

                    if (!existingLotProducts.length) {
                      quantity = 0;
                    } else {
                      const items = existingLotProducts
                        .filter(
                          element =>
                            (!transaction.product.toBinId && !transaction.product.fromBinId) ||
                            element.binId === transaction.product.toBinId ||
                            element.binId === transaction.product.fromBinId,
                        )
                        .map(element => parseFloat(element.unitQuantity));
                      if (!items.length) {
                        quantity = 0;
                      } else {
                        quantity = items.reduce((a, b) => a + b);
                      }
                    }

                    return (
                      <Grid container>
                        <Grid item xs={10}>
                          <Grid container>
                            <Grid item>
                              <p>{item.number}</p>
                              {item.expirationDate ? (
                                <p className='text-sm text-gray-400'>{`${dayjs(item.expirationDate)
                                  .toDate()
                                  .toDateString()}`}</p>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} my={'auto'} textAlign={'end'}>
                          <p>{quantity}</p>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Grid>
            ) : null}
            {!container ? (
              <>
                <Grid item xs={6}>
                  <TextInput
                    label={t().expectedQuantity.singular.label}
                    disabled
                    onChange={() => {}}
                    testId={testIds.expectedQuantity}
                    value={transaction.product.quantity.toString()}
                    suffix={
                      pmd ? getSuffix(productMasterData.get(transaction.product.pmdId || '')?.unitOfMeasure) : undefined
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    dynamicUpdate
                    label={t().processQuantity.singular.label}
                    disabled={
                      transaction.status !== ProductTransactionStatus.created ||
                      pmd?.lpnManaged ||
                      pmd?.serialManaged ||
                      disabled ||
                      container != null
                    }
                    onChange={v => setTransaction(transaction.withProcessedQuantity(parseFloat(v) || 0))}
                    testId={testIds.processQuantity}
                    value={transaction.product.processedQuantity?.toString()}
                    suffix={getSuffix(productMasterData.get(transaction.product.pmdId || '')?.unitOfMeasure)}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <CustomFieldInput
                item={relevantCustomFields.sort((a, b) => a.index - b.index)[0]}
                value={(() => {
                  return transaction.customFields?.get(relevantCustomFields.sort((a, b) => a.index - b.index)[0]?.id)
                    ?.value;
                })()}
                entity={transaction}
                onChange={setTransaction}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        }
        content={
          transaction.product.containerId ? (
            <Table<ProductTransaction>
              modal
              customPagination={5}
              title={'Container Contents'}
              items={getAllChildProductTransactions(transaction, productTransactions).filter(
                t => t.parentType !== ProductTransactionParentType.transaction,
              )}
              headers={productTransactionTableHeaders(
                new ProductTransactionTableSettings({
                  companyId: currentCompany.id,
                  columns: [
                    {
                      index: 0,
                      column: ProductTransactionColumn.productName,
                      label: 'Name',
                    },
                    {
                      index: 1,
                      column: ProductTransactionColumn.productNumber,
                      label: 'Number',
                    },

                    {
                      index: 2,
                      column: ProductTransactionColumn.quantity,
                      label: 'Quantity',
                    },
                  ],
                }),
                stockLocations,
                bins,
                productMasterData,
                lots,
                companyUsers,
                containers,
                containerTypes,
              )}
            />
          ) : null
        }
      />
    </ModalPane>
  );
}
