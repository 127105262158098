import { cloneDeep } from '@apollo/client/utilities';
import { StockLocationEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { ProductTransactionSpecifiers } from './productTransaction';
import { ShippingLocation } from './contact';
import { CustomFieldEntitySubType, CustomFieldValue, WithCustomFields } from './customField';
import { t } from './translation/Translator';

import { VentoryColor } from '../VentoryUI/util/color.util';
import { applyMixins } from '../util/mixins';

export enum OrderType {
  inbound = 'inbound',
  outbound = 'outbound',
  move = 'move',
  replenish = 'replenish',
}

// For testing
export const orderTypeToString = (type: OrderType): string => {
  switch (type) {
    case OrderType.inbound:
      return 'Inbound';
    case OrderType.outbound:
      return 'Outbound';
    case OrderType.replenish:
      return 'Replenish';
    case OrderType.move:
      return 'Move';
  }
};
export const orderTypeToLocalizedString = (type: OrderType) => {
  switch (type) {
    case OrderType.inbound:
      return t().inbound.singular.label || orderTypeToString(type);
    case OrderType.outbound:
      return t().outbound.singular.label || orderTypeToString(type);
    case OrderType.replenish:
      return t().replenish.singular.label || orderTypeToString(type);
    case OrderType.move:
      return t().move.singular.label || orderTypeToString(type);
  }
};
export const orderTypeToEntitySubType = (type: OrderType): CustomFieldEntitySubType => {
  switch (type) {
    case OrderType.inbound:
      return CustomFieldEntitySubType.inbound;
    case OrderType.outbound:
      return CustomFieldEntitySubType.outbound;
    case OrderType.replenish:
      return CustomFieldEntitySubType.replenish;
    case OrderType.move:
      return CustomFieldEntitySubType.move;
  }
};

export enum OrderStatus {
  open = 'open', // Contact not set

  created = 'created', // Contact set
  inProgress = 'inProgress',
  complete = 'complete',

  shipped = 'shipped',
  arrived = 'arrived',

  cancelled = 'cancelled',
  failed = 'failed',

  disabled = 'disabled',
  released = 'released',

  pickingComplete = 'pickingComplete',
}

export const orderStatusToLocalizedString = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.open:
      return t().open.singular.label;
    case OrderStatus.created:
      return t().created.singular.label;
    case OrderStatus.inProgress:
      return t().inProgress.singular.label;
    case OrderStatus.complete:
      return t().completed.singular.label;
    case OrderStatus.shipped:
      return t().shipped.singular.label;
    case OrderStatus.arrived:
      return t().arrived.singular.label;
    case OrderStatus.cancelled:
      return t().cancelled.singular.label;
    case OrderStatus.failed:
      return t().failed.singular.label;
    case OrderStatus.released:
      return t().released.singular.label;
    case OrderStatus.disabled:
      return t().disabled.singular.label;
    case OrderStatus.pickingComplete:
      return t().pickingComplete.singular.label;
  }
};
export const orderStatusToColorStyle = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.open:
    case OrderStatus.created:
      return {
        color: VentoryColor.green700,
        backgroundColor: VentoryColor.green50,
      };
    case OrderStatus.released:
      return {
        color: VentoryColor.blue700,
        backgroundColor: VentoryColor.blue50,
      };
    case OrderStatus.inProgress:
      return {
        color: VentoryColor.yellow700,
        backgroundColor: VentoryColor.yellow50,
      };
    case OrderStatus.complete:
      return {
        color: VentoryColor.green500,
        backgroundColor: VentoryColor.green50,
      };
    case OrderStatus.cancelled:
      return {
        color: VentoryColor.red700,
        backgroundColor: VentoryColor.red50,
      };
    case OrderStatus.disabled:
      return {
        color: VentoryColor.grey700,
        backgroundColor: VentoryColor.grey50,
      };
    case OrderStatus.pickingComplete:
      return {
        color: VentoryColor.blue700,
        backgroundColor: VentoryColor.blue50,
      };
    default:
      return {
        color: VentoryColor.grey700,
        backgroundColor: VentoryColor.grey50,
      };
  }
};

export enum OrderParentType {
  order = 'order',
  task = 'task',
}

export enum OrderReasonForFailure {}

export class Order extends StockLocationEntity {
  public stockLocationId!: string;
  public contactId?: string = undefined;
  public contactLocation?: ShippingLocation = undefined;
  public assignedTo: string[] = [];
  public type: OrderType = OrderType.inbound;
  public status: OrderStatus = OrderStatus.created;
  public products: Map<string, ProductTransactionSpecifiers> = new Map();
  public externalReferenceId?: string = undefined;
  public parentType?: OrderParentType;
  public parentId?: string;
  public purchaseOrderNumber?: string;
  public estimatedTimeOfArrival?: Date;
  public number?: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId, obj.stockLocationId);
    obj.customFields = WithCustomFields.toCustomFieldsMap(obj);
    Object.assign(this, cloneDeep(obj));
  }

  forCreate(): CreateOrderInput {
    return {
      ...this,
      customFields: this.customFieldValues(),
      products: [...this.products.values()].map(p => {
        delete p.id;

        return { ...p, customFields: p.customFieldValues() };
      }),
    };
  }

  forUpdate(): UpdateOrderInput {
    return {
      ...UpdateOrderInput.from(this, UpdateOrderInput),
      products: undefined,
      customFields: this.customFieldValues(),
    } as any;
  }

  forDelete(): DeleteOrderInput {
    return DeleteOrderInput.from(this, DeleteOrderInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Order)[], (field: keyof Order) => {
      return null;
    });
  }

  withStockLocationId(stockLocationId: string | undefined) {
    this.products = new Map();
    return super.withStockLocationId(stockLocationId);
  }

  withType(type: OrderType) {
    this.type = type;
    this.products = new Map();
    return cloneDeep(this);
  }

  withExternalReferenceId(id: string) {
    this.externalReferenceId = id;
    return this;
  }

  withContactId(contactId?: string) {
    this.contactId = contactId;
    this.contactLocation = undefined;
    return cloneDeep(this);
  }

  withContactLocation(location?: ShippingLocation | null) {
    this.contactLocation = location || undefined;
    return cloneDeep(this);
  }

  withAssignedUsers(ids: string[]) {
    this.assignedTo = ids;
    return cloneDeep(this);
  }

  withEstimatedTimeOfArrival(eta?: Date) {
    this.estimatedTimeOfArrival = eta;
    return cloneDeep(this);
  }

  withProduct(pmdId: string, customFieldValue?: CustomFieldValue, toContainerId?: string) {
    const specifier = new ProductTransactionSpecifiers({
      pmdId: pmdId,
      id: pmdId,
      quantity: 1,
      toStockLocationId: this.type === OrderType.inbound ? this.stockLocationId : undefined,
      fromStockLocationId: this.type === OrderType.inbound ? undefined : this.stockLocationId,
      toContainerId,
      customFields: customFieldValue ? [customFieldValue] : [],
    });
    this.products.set(orderSpecifierHash(specifier), specifier);
    return cloneDeep(this);
  }

  withContainer(containerId: string, customFieldValue?: CustomFieldValue) {
    const specifier = new ProductTransactionSpecifiers({
      containerId: containerId,
      id: containerId,
      quantity: 1,
      toStockLocationId: this.type === OrderType.inbound ? this.stockLocationId : undefined,
      fromStockLocationId: this.type === OrderType.inbound ? undefined : this.stockLocationId,
      customFields: customFieldValue ? [customFieldValue] : [],
    });
    this.products.set(orderSpecifierHash(specifier), specifier);
    return cloneDeep(this);
  }

  withSpecifierCustomField(specifierHash: string, customField: CustomFieldValue) {
    const existing = this.products.get(specifierHash);
    if (!existing) return cloneDeep(this);

    this.products.set(specifierHash, existing.withCustomField(customField));
    return cloneDeep(this);
  }

  withPurchaseOrderNumber(purchaseOrderNumber?: string) {
    this.purchaseOrderNumber = purchaseOrderNumber;
    return cloneDeep(this);
  }

  removeProducts(hashes: Set<string>) {
    hashes.forEach(hash => this.products.delete(hash));
    return cloneDeep(this);
  }
}

export interface Order extends WithCustomFields {}
applyMixins(Order, [WithCustomFields]);

export class CreateOrderInput extends forCreate(Order) {}

export class UpdateOrderInput extends forUpdate(Order) {}

export class DeleteOrderInput extends forDelete(Order) {}

export function orderSpecifierHash(specifier: ProductTransactionSpecifiers) {
  return orderSpecifierHashByValue(specifier.pmdId, specifier.containerId || specifier.toContainerId);
}

export function orderSpecifierHashByValue(pmdId?: string, containerId?: string) {
  return `${pmdId || ''}|${containerId || ''}`;
}
