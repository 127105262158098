import React, { useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { ProductTransaction } from '../../../../../types/productTransaction';
import ProcessOrderTransactionInfoPane from './Panes/ProcessOrderTransactionInfoPane';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';

interface ProcessOrderTransactionModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  transaction: ProductTransaction;
  disabled: boolean;
}

export default function ProcessOrderTransactionModal({
  open,
  setOpen,
  transaction,
  disabled = false,
}: ProcessOrderTransactionModalInputProps) {
  if (!open) return null;

  console.log(transaction.containerTransactionId);

  const [transactionInput, setTransactionInput] = useState<ProductTransaction>(new ProductTransaction(transaction));
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      title={t().processTransaction.singular.label}
      testId={testIds.processOrderTransactionModal}
    >
      <ProcessOrderTransactionInfoPane
        disabled={disabled}
        transaction={transactionInput}
        setTransaction={setTransactionInput}
        setError={setError}
        onClose={handleClose}
      />
    </Modal>
  );
}
