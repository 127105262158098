import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { t } from './translation/Translator';
import { CustomFieldEntitySubType, CustomFieldValue, WithCustomFields } from './customField';
import { VentoryColor } from '../VentoryUI/util/color.util';
import { applyMixins } from '../util/mixins';
import { Product } from './product';

enum ProductTransactionValidationError {}

export enum ProductTransactionType {
  inbound = 'inbound',
  outbound = 'outbound',
  move = 'move',
  replenish = 'replenish',
}

export enum ProductTransactionStatus {
  created = 'created',
  processed = 'processed',
  failed = 'failed',
  cancelled = 'cancelled',
  rollback = 'rollback',
  picked = 'picked',
}

export function productTransactionStatusToString(status: ProductTransactionStatus): string {
  switch (status) {
    case ProductTransactionStatus.created:
      return 'Created';
    case ProductTransactionStatus.processed:
      return 'Processed';
    case ProductTransactionStatus.rollback:
    case ProductTransactionStatus.cancelled:
      return 'Cancelled';
    case ProductTransactionStatus.failed:
      return 'Failed';
    case ProductTransactionStatus.picked:
      return 'Picked';
  }
}
export function productTransactionStatusToLocalizedString(status: ProductTransactionStatus): string {
  switch (status) {
    case ProductTransactionStatus.created:
      return t().created.singular.label;
    case ProductTransactionStatus.processed:
      return t().processed.singular.label;
    case ProductTransactionStatus.rollback:
      return t().rollback.singular.label;
    case ProductTransactionStatus.cancelled:
      return t().cancelled.singular.label;
    case ProductTransactionStatus.failed:
      return t().failed.singular.label;
    case ProductTransactionStatus.picked:
      return t().picked.singular.label;
  }
}
export function productTransactionStatusToColorClass(status: ProductTransactionStatus) {
  switch (status) {
    case ProductTransactionStatus.created:
      return {
        color: VentoryColor.blue700,
        backgroundColor: VentoryColor.blue50,
      };
    case ProductTransactionStatus.picked:
      return {
        color: VentoryColor.yellow700,
        backgroundColor: VentoryColor.yellow50,
      };
    case ProductTransactionStatus.processed:
      return {
        color: VentoryColor.green700,
        backgroundColor: VentoryColor.green50,
      };
    case ProductTransactionStatus.rollback:
      return {
        color: VentoryColor.orange700,
        backgroundColor: VentoryColor.orange50,
      };
    case ProductTransactionStatus.cancelled:
    case ProductTransactionStatus.failed:
      return {
        color: VentoryColor.red700,
        backgroundColor: VentoryColor.red50,
      };
  }
}
export const productTransactionTypeToCustomFieldEntitySubType = (type: ProductTransactionType) => {
  switch (type) {
    case ProductTransactionType.inbound:
      return CustomFieldEntitySubType.inbound;
    case ProductTransactionType.move:
      return CustomFieldEntitySubType.move;
    case ProductTransactionType.outbound:
      return CustomFieldEntitySubType.outbound;
  }
};

export enum ProductTransactionParentType {
  order = 'order',
  task = 'task',
  quickAction = 'quickAction',
  transaction = 'transaction', // For rollback
  upload = 'upload',
}

export function productTransactionParentTypeToString(type?: ProductTransactionParentType) {
  switch (type) {
    case ProductTransactionParentType.upload:
      return 'Upload';
    case ProductTransactionParentType.order:
      return 'Order';
    case ProductTransactionParentType.quickAction:
      return 'Quick Action';
    case ProductTransactionParentType.task:
      return 'Task';
    case ProductTransactionParentType.transaction:
      return 'Rollback';
  }
  return '';
}

export class ProductTransactionSpecifiers {
  id?: string;
  toStockLocationId?: string;
  fromStockLocationId?: string;
  toProductId?: string;
  fromProductId?: string;
  lotId?: string;
  containerId?: string;
  pmdId?: string;
  serialNbr?: string;
  lpn?: string;
  toBinId?: string;
  fromBinId?: string;
  fromContainerId?: string;
  toContainerId?: string;
  countryOfOrigin?: string;
  quantity!: number;
  processedQuantity?: number;

  constructor(obj: any) {
    obj.customFields = WithCustomFields.toCustomFieldsMap(obj);
    Object.assign(this, cloneDeep(obj));
  }
}

export interface ProductTransactionSpecifiers extends WithCustomFields {}
applyMixins(ProductTransactionSpecifiers, [WithCustomFields]);

interface ProductTransactionMetaData {
  toQuantity?: string;
  fromQuantity?: string;
}

export class ProductTransaction extends CompanyEntity {
  status!: ProductTransactionStatus;
  product!: ProductTransactionSpecifiers;
  reasonForFailure?: string;
  parentId?: string;
  parentType?: ProductTransactionParentType;
  type!: ProductTransactionType;
  processedAt?: string;
  processedBy?: string;
  pickedAt?: string;
  pickedBy?: string;
  metadata?: ProductTransactionMetaData;
  containerTransactionId?: string;
  processChildren: boolean = true;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
    this.customFields = WithCustomFields.toCustomFieldsMap(obj);
    this.product.processedQuantity = this.product.quantity;
  }

  forCreate(): CreateProductTransactionInput {
    return {
      ...this,
      customFields: this.customFieldValues(),
    };
  }

  override forUpdate(): UpdateProductTransactionInput {
    const pt = UpdateProductTransactionInput.from(this, UpdateProductTransactionInput);
    delete pt.metadata;
    return {
      ...pt,
      customFields: [...(this.customFields?.values() || [])],
    } as any as UpdateProductTransactionInput; // TODO: How is this done in order.ts?
  }

  override forDelete(): DeleteProductTransactionInput {
    return DeleteProductTransactionInput.from(this, DeleteProductTransactionInput);
  }

  override validate(fields: (keyof ProductTransaction)[]) {
    return this.validateEntity(fields, field => {
      return null;
    });
  }

  withType(type: ProductTransactionType) {
    this.type = type;

    if (type === ProductTransactionType.inbound) {
      this.product.fromStockLocationId = undefined;
      this.product.fromBinId = undefined;
    } else if (type === ProductTransactionType.outbound) {
      this.product.toStockLocationId = undefined;
      this.product.toBinId = undefined;
    }
    return cloneDeep(this);
  }

  withProcessChildren(processChildren: boolean) {
    this.processChildren = processChildren;

    return cloneDeep(this);
  }

  withToStockLocationId(stockLocationId?: string | null) {
    this.product.toStockLocationId = stockLocationId || undefined;
    this.product.toBinId = undefined;
    return cloneDeep(this);
  }

  withFromStockLocationId(stockLocationId?: string | null) {
    this.product.fromStockLocationId = stockLocationId || undefined;
    this.product.fromBinId = undefined;
    return cloneDeep(this);
  }

  withToBinId(binId?: string, virtualContainerSelected: boolean = false) {
    this.product.toBinId = binId;
    if (!virtualContainerSelected) this.product.toContainerId = undefined;
    return cloneDeep(this);
  }

  withFromBinId(binId?: string) {
    this.product.fromBinId = binId;
    this.product.fromContainerId = undefined;
    return cloneDeep(this);
  }

  withToContainerId(containerId?: string) {
    this.product.toContainerId = containerId;
    return cloneDeep(this);
  }

  withFromContainerId(containerId?: string) {
    this.product.fromContainerId = containerId;
    if (this.product.toContainerId === containerId) this.product.toContainerId = undefined;
    return cloneDeep(this);
  }

  withFromProductId(id?: string) {
    this.product.fromProductId = id;
    return cloneDeep(this);
  }

  withFromProduct(product: Product) {
    this.product.fromProductId = product.id;
    this.product.fromStockLocationId = product.stockLocationId;
    this.product.fromBinId = product.binId;
    this.product.fromContainerId = product.containerId;
    this.product.lotId = product.lotId;
    this.product.serialNbr = product.serial;
    this.product.lpn = product.lpn;
    return cloneDeep(this);
  }

  withSerial(serial?: string) {
    this.product.serialNbr = serial;
    return cloneDeep(this);
  }

  withLPN(lpn?: string) {
    this.product.lpn = lpn;
    return cloneDeep(this);
  }

  withLotId(lotId?: string) {
    this.product.lotId = lotId;
    return cloneDeep(this);
  }

  withQuantity(quantity: number) {
    this.product.quantity = quantity;
    return cloneDeep(this);
  }

  withProcessedQuantity(quantity: number) {
    this.product.processedQuantity = quantity;
    return cloneDeep(this);
  }

  withCustomField(customFieldValue: CustomFieldValue) {
    this.customFields.set(customFieldValue.id, customFieldValue);
    return cloneDeep(this);
  }

  withContainerId(containerId?: string) {
    this.product.containerId = containerId;
    return cloneDeep(this);
  }
}

export interface ProductTransaction extends WithCustomFields {}
applyMixins(ProductTransaction, [WithCustomFields]);

export class CreateProductTransactionInput extends forCreate(ProductTransaction) {}

export class UpdateProductTransactionInput extends forUpdate(ProductTransaction) {}

export class DeleteProductTransactionInput extends forDelete(ProductTransaction) {}
