import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { Order, OrderStatus, OrderType, orderTypeToLocalizedString } from '../../../../../types/order';
import TextInput from '../../../Common/TextInput';
import { ProductTransaction } from '../../../../../types/productTransaction';
import { t } from '../../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { ProductTransactionContext } from '../../../../../context/ProductTransactionContext';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { UserContext } from '../../../../../context/UserContext';
import ProcessOrderTransactionModal from '../Modals/ProcessOrderTransactionModal';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { ProductTransactionTableSettingsContext } from '../../../../../context/ProductTransactionTableSettingsContext';
import {
  ProductTransactionColumn,
  ProductTransactionTableSettings,
  ProductTransactionTableType,
} from '../../../../../types/productTransactionTableSettings';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { productTransactionTableHeaders } from '../../../Common/ProductTransactionTable';
import { BinContext } from '../../../../../context/BinContext';
import { LotContext } from '../../../../../context/LotContext';
import { ContainerContext } from '../../../../../context/ContainerContext';
import { ContainerTypeContext } from '../../../../../context/ContainerTypeContext';
import Paper from '../../../../../VentoryUI/components/common/Paper';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';
import Table from '../../../../../VentoryUI/components/common/Table';

interface OrderDetailPaneProps {
  order: Order;
  setOrder: (order: Order) => void;
  footer: () => JSX.Element;
}

export default function OrderDetailPaneProps({ order, setOrder, footer }: OrderDetailPaneProps) {
  const navigate = useNavigate();

  const { stockLocations } = useContext(StockLocationContext);
  const { bins } = useContext(BinContext);
  const { lots } = useContext(LotContext);
  const { productTransactions, productTransactionsLoading } = useContext(ProductTransactionContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { companyUsers } = useContext(UserContext);
  const { productTransactionTableSettings } = useContext(ProductTransactionTableSettingsContext);
  const { currentCompany } = useContext(CompanyContext);
  const { containers, containersLoading } = useContext(ContainerContext);
  const { containerTypes, containerTypesLoading } = useContext(ContainerTypeContext);

  const tableSettings = useMemo(() => {
    const existingSettings = [...productTransactionTableSettings.values()].find(
      el => el.type === ProductTransactionTableType.orderTransaction,
    );
    return new ProductTransactionTableSettings(
      existingSettings || { companyId: currentCompany.id },
      existingSettings?.type || ProductTransactionTableType.orderTransaction,
    );
  }, [productTransactionTableSettings]);

  const [selected, setSelected] = useState<ProductTransaction | null>(null);
  const [openProcessModal, setOpenProcessModal] = useState<boolean>(false);

  const orderTransactions = useMemo(() => {
    return [...productTransactions.values()]
      .flat()
      .filter(p => p.parentId === order.id)
      .sort((a, b) => (a.product.containerId && !b.product.containerId ? 1 : -1));
  }, [productTransactions]);

  return (
    <>
      {selected ? (
        <ProcessOrderTransactionModal
          disabled={order.status === OrderStatus.disabled}
          open={openProcessModal}
          setOpen={v => {
            setOpenProcessModal(v);
            setSelected(null);
          }}
          transaction={selected}
        />
      ) : null}

      <FlexPane
        header={
          <Paper>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={t().order.singular.label}
                  value={order.number || '0'}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={t().orderType.singular.label}
                  testId={testIds.type}
                  value={orderTypeToLocalizedString(order.type)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  disabled
                  dynamicUpdate
                  onChange={() => {}}
                  label={
                    order.type === OrderType.inbound
                      ? t().destinationStockLocation.singular.label
                      : t().sourceStockLocation.singular.label
                  }
                  testId={testIds.stockLocation}
                  value={stockLocations.get(order.stockLocationId)?.name || ''}
                />
              </Grid>
            </Grid>
          </Paper>
        }
        content={
          <Table
            title={t().transactions.singular.label}
            loading={
              productTransactionsLoading || productMasterDataLoading || containersLoading || containerTypesLoading
            }
            items={orderTransactions.sort(t => (t.product.containerId ? -1 : 1))}
            onClick={item => {
              setSelected(item);
              setOpenProcessModal(true);
            }}
            headers={productTransactionTableHeaders(
              tableSettings,
              stockLocations,
              bins,
              productMasterData,
              lots,
              companyUsers,
              containers,
              containerTypes,
            ).filter(h => {
              switch (order.type) {
                case OrderType.inbound:
                  if (h.column === ProductTransactionColumn.sourceContainer) return false;
                  return true;
                case OrderType.outbound:
                  if (h.column === ProductTransactionColumn.destinationContainer) return false;
                  return true;
              }
              return true;
            })}
          />
        }
        footer={footer()}
      />
    </>
  );
}
