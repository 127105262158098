import React from 'react';
import { OrderStatus, orderStatusToColorStyle, orderStatusToLocalizedString } from '../../../types/order';
import StatusLabel from '../../../VentoryUI/components/common/StatusLabel/StatusLabel';

interface OrderStatusLabelProps {
  status: OrderStatus;
  justify?: 'start' | 'center' | 'end';
}

export default function OrderStatusLabel({ status }: OrderStatusLabelProps) {
  return <StatusLabel color={orderStatusToColorStyle(status)} text={orderStatusToLocalizedString(status)} />;
}
