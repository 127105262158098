import React from 'react';
import { OrderType, orderTypeToLocalizedString } from '../../../../../types/order';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';

export default function OrderTypeFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<string>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.orderType || [])]}
      text={item => orderTypeToLocalizedString(item as OrderType)}
      onRemove={i => {
        filterProps.orderType?.delete(i);
        if (!filterProps.orderType?.size) filterProps.orderType = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
