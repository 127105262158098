import { gql } from '@apollo/client';
import { TaskStatus } from '../types/task';
import { OrderStatus } from '../types/order';
import { DashboardDynamicFilterParameters, DashboardEntityType } from '../ui/pages/Dashboard/Common/dashboard.util';
import { ChartData } from '../ui/pages/Dashboard/Graphs/GraphContainer';
import { FilterProps, FilterStorageKey, FilterParameters } from '../VentoryUI/components/filters/common/filter.util';
import dayjs from 'dayjs';

export interface DashboardParamters {
  type?: DashboardEntityType;
  startDate?: string;
  endDate?: string;
  taskStatus?: TaskStatus[];
  orderStatus?: OrderStatus[];
  stockLocationIds?: string[];
  country?: string[];
  binIds?: string[];
  productReferenceDataIds?: string[];
  userIds?: string[];
  additionalFilters?: string[];
}

export function toDashboardParameters(
  type: DashboardEntityType,
  parameters?: DashboardDynamicFilterParameters,
  additionalFilters?: string[],
): DashboardParamters {
  return {
    type,
    startDate: parameters?.date?.start.toISOString(),
    endDate: parameters?.date?.end.toISOString(),
    taskStatus: [...(parameters?.taskStatus?.values() || [])],
    orderStatus: [...(parameters?.orderStatus?.values() || [])],
    stockLocationIds: [...(parameters?.stockLocationIds?.values() || [])],
    country: [...(parameters?.country?.values() || [])],
    binIds: [...(parameters?.binIds?.values() || [])],
    productReferenceDataIds: [...(parameters?.productReferenceDataIds?.values() || [])],
    userIds: [...(parameters?.userIds?.values() || [])],
    additionalFilters: additionalFilters,
  };
}

export function toFilterParameters(
  type: FilterStorageKey,
  parameters?: FilterProps,
  additionalFilters?: string[],
): FilterParameters {
  return {
    type,
    startDate: parameters?.timeFrame ? dayjs(parameters.timeFrame.start).toISOString() : undefined,
    endDate: parameters?.timeFrame ? dayjs(parameters.timeFrame.end).toISOString() : undefined,
    taskStatus: [...(parameters?.taskStatus?.values() || [])] as TaskStatus[],
    orderStatus: [...(parameters?.orderStatus?.values() || [])] as OrderStatus[],
    stockLocationIds: [...(parameters?.stockLocation?.values() || [])],
    country: [...(parameters?.country?.values() || [])],
    binIds: [...(parameters?.bin?.values() || [])],
    productReferenceDataIds: [...(parameters?.product?.values() || [])],
    userIds: [...(parameters?.user?.values() || [])],
    additionalFilters: additionalFilters,
  };
}

const get = gql`
  query DashboardQuery($companyId: String!, $parameters: DashboardFilterParameters!, $locale: String!) {
    dashboardQuery(companyId: $companyId, parameters: $parameters, locale: $locale) {
      filteredChartData {
        label
        value
      }
      total
      format
    }
  }
`;

export interface GetDashboardFilterVariables {
  companyId: string;
  locale: string;
  parameters: DashboardParamters | FilterParameters;
}

export interface GetDashboardFilterResponse {
  dashboardQuery: {
    filteredChartData: ChartData[];
    total: number;
    format: string;
  };
}

export const DashboardQueries = {
  getProductTransactions: get,
  get,
};
